/* For WebKit (Chrome, Safari, etc.) */
::-webkit-scrollbar {
  width: 4px; /* Adjust the width as needed */
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-primary); /* Adjust the color */
}

::-webkit-scrollbar-track {
  background-color: var(--color-primary); /* Adjust the color */
}

/* For Firefox */
.scrollbar-thumb-primary {
  background-color: var(--color-primary); /* Adjust the color */
}

.scrollbar-track-primary {
  background-color: var(--color-primary); /* Adjust the color */
}
